<script>
import LineChart from "@/plugins/charts/LineChart";

export default {
    name: "Count",

    components: {LineChart},

    data() {
        return {
            cData: null,
            opts: {
                responsive: true,
            }
        }
    },

    mounted() {
        this.init();
    },

    computed: {
        chartData() { return this.cData },
        chartOptions() { return this.opts }
    },

    methods: {
        init() {
            let chartData = {
                "labels": [
                ],
                "datasets": [
                    {
                        "fill": false,
                        "label": this.$t('buyer_order.report.sum'),
                        "data": [],
                        "borderColor": ["rgba(97, 100, 110, 1)"],
                        "borderWidth": 2
                    }
                ]
            };
            this.fetchCountOfBuyerOrders().then((report) => {
                for(let i in report) {
                    chartData.labels.push(i);
                    chartData.datasets[0].data.push(report[i]);
                }

                this.cData = chartData;
            });
        },

        fetchCountOfBuyerOrders() {
            return new Promise((resolve, reject) => {
                this.$http.get('admin/buy/order_to_buy/report/sum')
                    .then(response => {
                        resolve(response.body);
                    })
                    .catch(error => {
                        this.$toastr.error(error.body.message);
                        reject(error);
                    });
            })

        }
    }
}
</script>

<template>
    <v-card>
        <v-card-text>
            <LineChart
                :options="chartOptions"
                :chart-data="JSON.stringify(chartData)"
            ></LineChart>
        </v-card-text>
    </v-card>
</template>

<style scoped lang="scss">

</style>
